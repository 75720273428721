import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[1]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[3]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[7]}")` }}
        ></figure>
        <div className="z-10 relative md:pt-[250px] pt-[200px] pb-[120px] w-4/5 mx-auto">
          <div className="md:w-1/2 w-full">
            <h1 className="text-white md:text-start text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white md:text-start text-center">{rpdata?.dbValues?.[1].description}</p>
            <ButtonContent />
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
