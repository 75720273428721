import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollProgressBar from "./ScrollProgressBar";

function NavBar() {
  const { rpdata } = useContext(GlobalDataContext);

  //Recorrer el array de los servicios
  const subitems = rpdata?.dbServices?.map((item) => {
    return {
      link: `/${item.name.replace(" ", "-").toLowerCase()}`,
      name: item.name,
    };
  });

  const Navigation = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Services",
      path: "/services",
      child: rpdata?.autoGntLandingFromService,
      submenu: [...(subitems ? subitems : [])],
    },
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Reviews",
      path: "/reviews",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  return (
    <div className="relative z-50 w-full">
      <header>
        <ScrollProgressBar />
        <div className="bg-[#FF8201] p-2">
          <div className="md:w-4/5 mx-auto md:flex justify-between items-center">
            <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
              <li className="text-white md:text-[17px] text-[13px] hidden md:block ">
                <span className="border-r-[1px] border-white pr-3">

                {rpdata?.dbPrincipal?.location[0]?.address}
                </span>
              </li>
              <li className="text-white md:text-[17px] text-[13px] ">
                <span className="border-r-[1px] border-white pr-3">

                {rpdata?.dbPrincipal?.workdays[0]?.day}
                </span>
              </li>
              <li className="text-white md:text-[17px] text-[13px] ">
                <span className="pr-3">

                {rpdata?.dbPrincipal?.workHours[0]?.hour}
                </span>
              </li>
            </ul>
            <ul className="flex justify-center space-x-7">
              {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <li key={index} className="text-white">
                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                      <i
                        className={`fab fa-${item.icon}`}
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="bg-transparent absolute right-[5%] left-[5%]">
          <nav className="flex flex-wrap items-center justify-between py-2 md:py-6">
            <div className="md:w-[50%] w-[80%] ">
              <img
                src={rpdata?.dbPrincipal?.logo}
                className="md:w-[30%] w-[70%]"
                alt="Not Found"
                loading="lazy"
              />
            </div>
            <div className="text-center flex justify-center">
              <button
                id="menu-button"
                className="cursor-pointer md:hidden block w-[100%] burguer-menu"
                onClick={() => {
                  document.querySelector("#menu").classList.toggle("hidden");
                }}
              >
                <GiHamburgerMenu />
              </button>
            </div>
            <div
              className="hidden w-full md:flex md:items-center md:w-[50%] justify-end md:bg-transparent bg-white rounded-sm" id="menu"
            >
              <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 p-4">
                {Navigation.map((item, index) => {
                  return (
                    <li key={index} className="md:flex md:relative group">
                      {item.child ? (
                        <Link
                          to="#"
                          className="md:p-4 py-2 px-4 block md:text-white text-black"
                          onClick={
                            () => {
                              document.querySelector("#mobilemenu").classList.toggle("hidden");
                            }
                          }
                        >
                          {item.name}
                          <i className="fas fa-angle-down ml-2"></i>
                        </Link>
                      ) : (
                        <Link to={item.path} className="md:p-4 py-2 px-4 block md:text-white text-black" >{item.name}</Link>
                      )}
                      {item.child && (
                        <ul className="md:absolute sm:overflow-y-scroll hidden z-10 bg-white p-3 w-60 top-14 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top" id="mobilemenu">
                          {item.submenu.map((subItem, subIndex) => {
                            return (
                              <li key={subIndex} className="hover:bg-slate-100 leading-8 ml-4">
                                <Link to={subItem.link}>{subItem.name}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default NavBar;
